import React from "react"

import Heading from "../Layout/Heading"

import "./index.scss"

import michael from "./images/michael.jpg"

const Michael = () => (
  <>
    <Heading title="The Coastline Newspaper Group" />
    <div className="row michael">
      <div className="col-lg-9 col-md-8">
        <div className="row">
          <div className="col-12">
            <p>
              Welcome to the website for Coastline Newspaper Group. Here we let
              you preview past editions of our publications, check out our
              interesting stories and pictures, mainly with a tourist flavour.
            </p>
            <p>
              We invite you to contact us if you would like advertising details
              or if you have an item of interest for us.
            </p>
            <p>
              Do you have a comment about our publications, app or website? We
              would be pleased to hear from you.
            </p>
          </div>
          <div className="col-12 michael-blockquote">
            <p className="blockquote-footer">
              Michael McRae - Managing Director
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-4 text-center">
        <img src={michael} alt="Michael McRae" className="michael-image" />
      </div>
    </div>
  </>
)

export default Michael
