import React from "react"
import { Link } from "gatsby"
import Button from "react-bootstrap/Button"

import Icon from "../Icon"

import "./index.scss"

const competitionsFiles = `${process.env.FILE_BUCKET}/competitions`

const Competitions = ({ competitions }) => {
  const numCompetitions = competitions.length
  const limit = 2

  return (
    <>
      <div className="row">
        <div className="col text-center competition-heading">
          <h1>Competitions</h1>
          <p>Enter our competitions and win prizes</p>
        </div>
      </div>
      <div className="row">
        {competitions.slice(0, limit).map((competition) => (
          <div className="col-md-6 article" key={competition.id}>
            {competition.image && (
              <img
                src={`${competitionsFiles}/${competition.image}`}
                alt={competition.title}
                className="competition-image"
              />
            )}
            <h3>{competition.title}</h3>
            <p>{competition.description}</p>
            <Link to={`/competition/${competition.id}`}>
              <Button variant="primary" bsPrefix="cln">
                More Info
              </Button>
            </Link>
          </div>
        ))}
      </div>
      {numCompetitions > limit && (
        <div className="row justify-content-center">
          <div className="col-10 col-md-8 col-lg-6 text-center view-all-button-container">
            <Link to="/competitions">
              <Button variant="outline-primary" bsPrefix="cln" size="lg" block>
                View all Competitions{" "}
                <Icon prefix="fas" name="arrow-circle-right" />
              </Button>
            </Link>
          </div>
        </div>
      )}
    </>
  )
}

export default Competitions
