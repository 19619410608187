import React from "react"

import Heading from "../Layout/Heading"

import "./index.scss"

import AppIcon from "./images/app.png"
import AppStore from "./images/app-store.png"

const App = () => (
  <>
    <Heading title="The Bundy Book App" />
    <div className="row justify-content-center app">
      <div className="col-lg-9 col-md-8">
        <div className="row">
          <div className="col-lg-3 col-12 text-center">
            <img className="app-icon" src={AppIcon} alt="The Bundy Book App" />
          </div>
          <div className="col-lg-9 col-12 app-details">
            <div className="row">
              <div className="col-12">
                <p>
                  Coming to Bundaberg? Make sure you have the Bundy Book App.
                </p>
                <p>
                  This app is available to anyone in the world and its free from
                  the App Store. The app is the perfect partner to the Bundy
                  Book, Bundaberg's glossy colourful tourist magazine available
                  free from most tourist places.
                </p>
                <p>Get your Bundy Book App now and its FREE.</p>
              </div>
              <div className="col-12">
                <a
                  href="https://apps.apple.com/au/app/bundy-book/id1437987146"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="app-store-icon"
                    src={AppStore}
                    alt="Download on the App Store"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default App
