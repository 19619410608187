import React from "react"
import { Link } from "gatsby"
import Button from "react-bootstrap/Button"

import Icon from "../Icon"

import "./index.scss"

const News = ({ news }) => {
  const numArticles = news.length
  const limit = 6
  return (
    <>
      <div className="row">
        <div className="col text-center news-heading">
          <h1>Regional Roundup</h1>
          <p>News from Bundaberg and surrounds</p>
        </div>
      </div>
      {numArticles === 0 ? (
        <div className="row">
          <div className="col-12 text-center">
            <p>No news at the moment, please check back soon</p>
          </div>
        </div>
      ) : (
        <>
          <div className="row">
            {news.slice(0, limit).map((article) => (
              <div className="col-md-4 article" key={article.id}>
                <h3>{article.title}</h3>
                <p>{article.description}</p>
                <Link to={`/article/${article.id}`}>
                  <Button variant="primary" bsPrefix="cln">
                    Read More
                  </Button>
                </Link>
              </div>
            ))}
          </div>
          {numArticles > limit && (
            <div className="row justify-content-center">
              <div className="col-10 col-md-8 col-lg-6 text-center view-all-button-container">
                <Link to="/news">
                  <Button
                    variant="outline-primary"
                    bsPrefix="cln"
                    size="lg"
                    block
                  >
                    View all Articles{" "}
                    <Icon prefix="fas" name="arrow-circle-right" />
                  </Button>
                </Link>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default News
