import React from "react"
import "./index.scss"

const Heading = ({ title }) => (
  <div className="mainheading">
    <h1>{title}</h1>
  </div>
)

export default Heading
