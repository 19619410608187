import React from "react"

import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Michael from "../components/Michael"
import Publications from "../components/Publications"
import App from "../components/App"
import News from "../components/News"
import Competitions from "../components/Competitions"

const Index = ({ location, pageContext }) => {
  const { publications, news, competitions } = pageContext
  return (
    <Layout location={location}>
      <SEO
        title="Home"
        description="Coastline Newspaper Group is an independent family company which printed its first newspaper in 1986."
      />
      <Michael />
      <Publications publications={publications} />
      <App />
      <News news={news} />
      <Competitions competitions={competitions} />
    </Layout>
  )
}

export default Index
